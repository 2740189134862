import React from "react"

import { StaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo" 

// var title = process.env.
const AboutPage = ({  data }) => (

  <StaticQuery
    query={graphql`
      query AboutPageQuery {
        site {
          siteMetadata {
            title
            url
            blogUrl
            appUrl
            getStartedUrl
            twitterUrl
            linkedInUrl
            feedbackUrl
            description
            homeDescription
            homeDescription2
            homeDescription3
            footerDescription
          }
        }
      }
    `}
    render={data => (
  <Layout>
    <SEO title="About" />

    <div className={"page-header"}>
      <h2>What’s {data.site.siteMetadata.title}?</h2>
      <p>
        {data.site.siteMetadata.title} is a service & platform that is helping to build a
        better, smarter and safer Internet for all.
      </p>
    </div>
    <section className="features features-3">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-md-offset-1 col-sm-12 text-center">
            <h3>
            Work, learn & communicate more safely online
            </h3>
            {/* <h4>
              We help your team save time by pinpointing unclear and potentially
              risky parameters and information where data privacy and personal
              privacy terms are in user agreements and policy documents.
            </h4> */}
             {/* <p>
                    By proactively monitoring, disclosing, annotating and
                    analyzing your own organization and your vendor's, you can
                    ensure a better future for your customers, team and bottom
                    line.
                  </p> */}
          </div>
        </div>
       
      </div>
    </section>
   
    <div className={"container"}>
      <div className={"targets"}>
        <div className={"title"}>
          {/* <h2>Solving Data Transparency</h2> */}
          <h2>What We Do</h2>
        </div>

        <div className={"row"}>
          <div className={"col-md-6"}>
            <div className={"item"}>
              {/* <img alt={"Information"} src={informationImage}/> */}
              <h2>People</h2><br/>
              <p>
                Reading user agreements and privacy policies are not a good use
                of time or effective in vetting privacy and security
                protections. {data.site.siteMetadata.title} allows people to find and analyze
                consented policies, agreements, track record and related
                information free of charge in addition to ongoing monitoring.
              </p>
            </div>
          </div>

          <div className={"col-md-6"}>
            <div className={"item"}>
              {/* <img alt={"Event"} src={eventImage}/> */}
              <h2>Business</h2><br/>
              <p>
                How to maintain compliance and assurances is one of the major
                problems of companies that collect or process personal and
                privacy data especially with a growing world of fragmented
                standards and regulations. {data.site.siteMetadata.title} allows organizations,
                their procurement teams, and their third party vendors to get
                transparent information for data protection standards.
              </p>
            </div>
          </div>
        </div>

        <div className={"row"}>
          <div className={"col-md-6"}>
            <div className={"item"}>
              {/* <img alt={"Productivity"} src={ProductivityImage}/> */}
              <h2>Auditors</h2><br/>
              <p>
                Currently auditors and assessors of various privacy and
                cybersecurity standards store this information in a fragmented
                way and often it's not quick or easy to find this information.
                It's often not clear if an auditor really has certified a tech
                company for a standard or when. {data.site.siteMetadata.title} provides a
                trusted place for auditors and companies to disclose and
                showcase the track record and history of compliance audits.
              </p>
            </div>
          </div>

          <div className={"col-md-6"}>
            <div className={"item"}>
              {/* <img alt={"Happiness"} src={happinessImage}/> */}
              <h2>Authorities</h2><br/>
              <p>
                Governments, law enforcement and regulators are often addressing
                and enforcing laws often after a major data breach has occurred
                which affects people and customers often too late after damage
                is done. {data.site.siteMetadata.title} provides useful data since these
                organizations are often lacking insights to effectively monitor
                the trust and accountability of target businesses and
                organizations.
              </p>
            </div>
          </div>
        </div>
        

        </div>
      </div>
    <div className={"container"}>

    <div className="row">
          <div className="col-md-12 text-center pb-4">
          <Link to="/why" className="btn btn-primary  btn-sm">Learn More about Why {data.site.siteMetadata.title} Exists?</Link>
        </div>
      </div>
    </div>
    
<div className="call-to-action">
            <div className="container">
              <div className="call-to-action__content">
                <h2>Let's talk</h2>
                <p>
                  What are your biggest problems? Do you have
                  questions about possible solutions? We'd like to help. 
                </p>
              </div>
              <div className="button">
                <a href="/lets-talk/">Let's talk</a>
              </div>
            </div>
          </div>
  </Layout>
  )}
  />
  )

export default AboutPage
